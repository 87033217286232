jQuery(($) => {
  jQuery(window).on('load scroll', dockPrimaryNav)
  /**
  
     * Dock the primary nav when needed
  
     */
  
  function dockPrimaryNav() {
    const $mainNav = jQuery('#navbar--primary')
    const headerHeight = $('#header--site').height()
    const triggerDistance = $('main').offset().top
    const scrollDist = $(document).scrollTop()
    if (scrollDist > headerHeight) {
      $mainNav.addClass('scrolling')
      $('body').css('padding-top', $mainNav.height())
    } else {
      $mainNav.removeClass('scrolling')
      $('body').css('padding-top', 0)
      $mainNav.removeClass('fixed-top')
    }
    if (scrollDist >= triggerDistance) {
      $mainNav.addClass('fixed-top')
    }
  }
})

