/**
 * @since 1.0.0
 * @version 1.6.3
 */

import 'bootstrap/dist/js/bootstrap'
import './_ga'
import 'jquery-simulate'
import './_header'

jQuery(($) => {
  jQuery('.image-list__item img').on('click', function () {
    $(this).parents('.image-list__item').find('a').simulate('click')
  })

  jQuery(window).on('load resize', function () {
    jQuery('.block--ingredients-list').each((i, element) => {
      let topMargin =
        -1 * jQuery(element).find('svg').height()
      jQuery(element).css('margin-top', topMargin)
    })
  })
})
